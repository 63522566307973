import React, { Fragment } from "react";

const NotFoundPage = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      flexDirection: "column",
    }}
  >
    <h2>NOT FOUND</h2>
    <p>Sorry, we couldn't find anything here.</p>
  </div>
);

export default NotFoundPage;
